import React from "react";
import img2 from "./../Assets/Images/room2.jpeg";
import vid from "./../Assets/Images/room5.mp4"



export const Aboutus = ()=>{
    return(
        <>
        <section class="thirdsection">
                <section class="outer1">
                    <div class="inner3">
                        <a href={vid}><i class="fa fa-play" aria-hidden="true"></i></a>
                    </div>
                    <div class="inner4">
                        <img src={img2} alt=""/>
                    </div>
                </section>
                <section class="outer2">
                    <div>
                        <h1>About Us</h1>
                        <p>Paliwal Mess is a pure vegetarian bhojnalaya which has been providing people the convenience of eating deliciously or in a budget for last 21 years. We pay special attention to cleanliness and test here. Along with mess, we also provide the facility of tiffin and also take orders for parties. The nature of the staff here is very good and they take full care of the cleanliness and treat everyone well. We have access to all the mess city's top locations like school, college or hospital.</p>
                        <a href={vid}>WATCH VIDEO →</a>
                    </div>
                </section>
            </section>

            </>
    )
}

