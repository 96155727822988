import React from "react";
import img1 from "./../Assets/Images/it1.jpeg";
import img2 from "./../Assets/Images/it2.jpeg";
import img3 from "./../Assets/Images/room3.jpeg";
import img4 from "./../Assets/Images/room4.jpeg";
import img5 from "./../Assets/Images/it3.jpeg";
import img6 from "./../Assets/Images/it4.jpeg";



export const ITparkGallary =()=>{
    return(
        <>
        <section class="sixthsection">
                <div>
                    <h1>Our Gallery</h1>
                </div>
        </section>
        <section class="sevanthsection">
                <div>
                    <img src={img1} alt=""/>
                </div>
                <div>
                    <img src={img2} alt=""/>
                </div>
                <div>
                    <img src={img3} alt=""/>
                </div>
                <div>
                    <img src={img4} alt=""/>
                </div>
                <div>
                    <img src={img5} alt=""/>
                </div>
                <div>
                    <img src={img6} alt=""/>
                </div>
                
        </section>
        </>
    )
}