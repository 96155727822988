import React from "react";
import img1 from "./../Assets/Images/001-wifi.png";
import img2 from "./../Assets/Images/information-desk.png"
import img3 from "./../Assets/Images/fire-exit.png"
import img4 from "./../Assets/Images/car-parking.png"
import img5 from "./../Assets/Images/004-home.png"
import img6 from "./../Assets/Images/002-cctv-camera.png"
import img7 from "./../Assets/Images/003-restaurant.png"
import img8 from "./../Assets/Images/taxi.png"


export const Features = () =>{
    return(
        <>
        <section class="fourthsection">
            <section class="outer3">
                <div>
                    <h1>Hostel Features</h1>
                </div>
            </section>
            <section class="outer4">
                <div>
                    <img src={img1} alt=""/>
                    <h2>Free WiFi</h2>
                </div>
                <div>
                    <img src={img2} alt=""/>
                    <h2>Hostel Teller</h2>
                </div>
                <div>
                    <img src={img3} alt=""/>
                    <h2>Fire Exit</h2>
                </div>
                <div>
                    <img src={img4} alt=""/>
                    <h2>Car Parking</h2>
                </div>
                <div>
                    <img src={img5} alt=""/>
                    <h2>House Keeping</h2>
                </div>
                <div>
                    <img src={img6} alt=""/>
                    <h2>CCTV</h2>
                </div>
                <div>
                    <img src={img7} alt=""/>
                    <h2>Restaurent</h2>
                </div>
                <div>
                    <img src={img8} alt=""/>
                    <h2>Car Airport</h2>
                </div>
            </section>
        </section>
        </>
    )
}