import React from "react";
import {Link } from "react-router-dom";
import { MenuButton } from "./MenuButton";

export const Navbar = () => {
    const Menu = ["Home", "OurRooms", "Branches", "About", "Contact"];

    return (
<>
        <header className="Header">
            <div>
                <h1>PALIWAL mess</h1>
            </div>
            <ul>
                
                        <li>
                            <Link to='/'>Home</Link>
                        </li>
                        <li>
                            <Link to='/OurRooms'>OurRooms</Link>
                        </li>
                        <li>
                            <Link to='/OurBranches'>Branches</Link>
                        </li>
                        <li>
                            <Link to='/Aboutus'>About</Link>
                        </li>
                        <li>
                            <Link to='/ContactUs'>Contact</Link>
                        </li>       
            </ul>
        </header>
        
        </>
        
    );
};
