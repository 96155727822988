import { render } from "@testing-library/react";
import React from "react";
import { Link } from "react-router-dom";


export const MenuButton=()=>{
    return(
        <>
        <section className="MenuButton">
            <div className="MenuDiv">
                <Link to="/Menu" className="text-light text-decoration-none">Price & Menu</Link>
            </div>
        </section>
        </>
    )
}