import React from "react";
import img1 from "./../Assets/Images/menu1.jpeg";
import img2 from "./../Assets/Images/menu2.jpeg";
import img3 from "./../Assets/Images/menu3.jpeg";
import img4 from "./../Assets/Images/menu4.jpeg";




export const Menu =()=>{
    return(
        <>
        <section className="sixthsection">
                <div>
                    <h1>Price & Menu</h1>
                </div>
        </section>
        <section className="MenuCard">
                <div>
                    <a href="http://localhost:3000/static/media/menu2.cb5aa57be724f8240a51.jpeg"><img src={img1} alt=""/></a>
                </div>
                <div>
                    <a href="http://localhost:3000/static/media/menu2.cb5aa57be724f8240a51.jpeg"><img src={img2} alt=""/></a>
                </div>
                <div>
                    <a href="http://localhost:3000/static/media/menu3.37ddca10b6cdfac02dd0.jpeg"><img src={img3} alt=""/></a>
                </div>
                <div>
                    <a href="http://localhost:3000/static/media/menu4.2442d3e744474edd0c6f.jpeg"><img src={img4} alt=""/></a>
                </div>
                
        </section>
        </>
    )
}