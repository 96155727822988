import React from "react";
import img1 from "./../Assets/Images/front.jpeg"
import { Link } from "react-router-dom";


export const OurBranches = () => {
    return (
        <>
            <section class="secondsection">
                <div className="h1">
                    <h1> Our Branches</h1>
                </div>
            </section>

            <section className="BranchSection">

                <div className="BranchCardSaction">
                    <Link to="/GokulPethGallery">
                        <div className="BranchCardUpperSaction">
                            <img src={img1} alt="" />
                        </div>
                    </Link>

                    <div className="BranchCardLowerSaction">
                        <h3>GokulPeth</h3>
                        <h4>Nagpur</h4>
                        <a href="https://maps.app.goo.gl/5UWJk2VV3ShLTvDX7?g_st=iwb"><button>Get Location</button></a>
                    </div>
                </div>


                <div className="BranchCardSaction">
                    <Link to="/ITparkGallary">

                        <div className="BranchCardUpperSaction">
                            <img src={img1} alt="" />
                        </div>
                    </Link>

                    <div className="BranchCardLowerSaction">
                        <h3>IT Park, Subhash Nagar</h3>
                        <h4>Nagpur</h4>
                        <a href="https://maps.app.goo.gl/QZY4psDgRZUGrhYA6?g_st=iwb"><button>Get Location</button></a>

                    </div>
                </div>
            </section>
            <section className="BranchSection2">
                <div>
                    <h3><i class="fa-solid fa-arrow-right-long"></i> We Also Availabel :- RBU Univercity, Agarchand Chhatrawas, GS Collage, On Order </h3>
                </div>
            </section>
        </>
    )
}