import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import reportWebVitals from './reportWebVitals';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';  // Correct import
import { Header } from './Componants/Header';
import { OurRooms } from './Componants/OurRooms';
import { Aboutus } from './Componants/Aboutus';
import { Features } from './Componants/Features';
import { Offer } from './Componants/Offer';
import { Review } from './Componants/Review';
import { Footer } from './Componants/Footer';
import { OurBranches } from './Componants/OurBranches';
import { GokulPethGallery } from './Componants/GokulPethGallery';
import { ITparkGallary } from './Componants/ITparkGallary';
import { ContactUs } from './Componants/ContactUs';
import { Navbar } from './Componants/Navbar';
import { MenuButton } from './Componants/MenuButton';
import { Menu } from './Componants/Menu';

ReactDOM.render(
  <>
    <Router>
      <Navbar/>
      <MenuButton/>
      <Routes>
      <Route exact path="/" element={<Header />} />
        <Route path="/OurRooms" element={<OurRooms />} />
        <Route path="/Aboutus" element={<Aboutus />} />
        <Route path="/Features" element={<Features />} />
        <Route path="/Offer" element={<Offer />} />
        <Route path="/GokulPethGallery" element={<GokulPethGallery />} />
        <Route path="/ITparkGallary" element={<ITparkGallary />} />
        <Route path="/Review" element={<Review />} />
        <Route path="/OurBranches" element={<OurBranches />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/Menu" element={<Menu />} />

      </Routes>
      <Footer />
    </Router>
  </>,
  document.getElementById("root")
);




// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
  
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
