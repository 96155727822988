import React from "react";
// import { Navbar } from "./Navbar";
import img1 from "./../Assets/Images/room1.jpeg";
import img2 from "./../Assets/Images/room2.jpeg";
import img3 from "./../Assets/Images/room3.jpeg";
// import img4 from "./../Assets/Images/room4.jpeg";
import { Footer } from "./Footer";




export const OurRooms = () => {
    return (
        <>
        {/* <Navbar/> */}
            <section class="secondsection">
                <div className="h1">
                    <h1> Our Rooms</h1>
                </div>
            </section>
            <section class="outer">
                <div className="outer1">
                    <div class="inner">
                        <div className="img">
                            <img src={img1} />
                        </div>
                        <div className="roomtype">
                            <h2>PERSONAL ROOM</h2>
                            <h3>AC & NON-AC</h3>
                        </div>
                    </div>
                    <div class="inner">
                        <div className="img">
                            <img src={img2} />
                        </div>
                        <div className="roomtype">
                            <h2>SHARING ROOM</h2>
                            <h3>AC & NON-AC</h3>
                        </div>
                    </div>    <div class="inner">
                        <div className="img">
                            <img src={img3} />
                        </div>
                        <div className="roomtype">
                            <h2>STANDARD ROOM</h2>
                            <h3>AC & NON-AC</h3>
                        </div>
                    </div>
                </div>
                <div className="outer1">
                    <div class="inner">
                        <div className="img">
                            <img src={img1} />
                        </div>
                        <div className="roomtype">
                            <h2>PERSONAL ROOM</h2>
                            <h3>AC & NON-AC</h3>
                        </div>
                    </div>
                    <div class="inner">
                        <div className="img">
                            <img src={img2} />
                        </div>
                        <div className="roomtype">
                            <h2>SHARING ROOM</h2>
                            <h3>AC & NON-AC</h3>
                        </div>
                    </div>    <div class="inner">
                        <div className="img">
                            <img src={img3} />
                        </div>
                        <div className="roomtype">
                            <h2>STANDARD ROOM</h2>
                            <h3>AC & NON-AC</h3>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Footer/> */}
        </>
    )

}