import React from "react";


export const Offer = () =>{
    return(
        <>
        <section class="fifthsection">
                <div>
                    <h1>Summer Promo 20% Off</h1>
                    <button>AVAIL NOW</button>
                </div>
                <div>
                    <h4>THE PROMO WILL START IN</h4>
                    <span class="label">
                        <span>00</span>
                        weeks
                        <span>00</span>
                        days
                        <span>00</span>
                        hr
                        <span>00</span>
                        min
                        <span>00</span>
                        sec
                    </span>
                </div>
        </section>
        </>
    )
}