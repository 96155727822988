import React from "react";
import img1 from "./../Assets/Images/WhatsApp Image 2024-12-12 at 12.39.18 AM.jpeg";
import img2 from "./../Assets/Images/WhatsApp Image 2024-12-12 at 12.39.17 AM.jpeg"


export const Review = ()=>{
    return(
        <>
        <section class="tenthsection">
            <h1>What People Say</h1>
        </section>
        <section class="elevnthsection">
            <div class="inner4">
                <img src={img1} alt=""/>
                <div>
                <h2>Ved</h2>
                <p>"Awesome 😍, if you're a student then you should definitely join Paliwal as your mess, it's equivalent to "Ghar ka khana" with combination of everything i.e Southindian on Tuesday, feast on Thursday and Sunday Morning."</p>
                </div>
            </div>
            <div class="inner5">
                <img src={img2} alt=""/>
                <div>
                <h2>Sheetal</h2>
                <p>"Serves good food with variety including feast once in a week..well treatment of visitors..seating arrangements r also proper..one can recommend it for students to join it on monthly basis."</p>
                </div>
            </div>
        </section>
        </>
    )
}