import React from "react";
import img1 from "./../Assets/Images/logoWS.png";

export const ContactUs = () => {
    return (
        <>
            <section className="ContactusSection">
                <div className="Contactusformdiv">
                    <div className="Contactusinnerdiv">
                        <div className="div1">
                            <h1>ContactUS</h1>
                        </div>
                        <div className="div2">
                            <p><a href="mailto:paliwalmess2007@gmail.com"><i class="fa-solid fa-envelope"></i> paliwalmess2007@gmail.com</a>
                            </p>
                        </div>
                        <div className="div2">
                            <p><a href="https://api.whatsapp.com/send?phone=918983042650&text="><i class="fab fa-whatsapp"></i> +91-8983042650</a></p>
                        </div>
                        <div className="div3">
                            <p><a href="tel:+918983042652"><i class="fa-solid fa-phone"></i> +91-8983042652</a></p>
                        </div>
                        <div className="div4">
                            <p><a href="https://www.instagram.com/paliwalmessngp?igsh=MWlkY3pkbTVyaTF5Zw=="><i class="fa-brands fa-instagram"></i> paliwalmessngp</a></p>
                        </div>
                    </div>
                </div>
                <div className="Contactuslogodiv">
                    <img src={img1} alt="" />
                </div>
            </section>

        </>
    )
}
