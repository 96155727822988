import React from "react";
import { Link } from "react-router-dom";


export const Footer =()=>{
    return(
        <footer>
            <section class="footer">
            <div class="end2">
                <h1>About</h1>
                <p>
                Paliwal Mess is a pure vegetarian bhojnalaya which has been providing people the convenience of eating deliciously or in a budget for last 21 years.
                </p>
                <Link to="/Aboutus"><button>Read More</button></Link>
            </div>
            <div class="end">
                <h1>Quick Menu</h1>
                <ul>
                    <li>About</li>
                    <li>Services</li>
                    <li>Approach</li>
                    <li>Sustainability</li>
                    <li>News</li>
                    <li>Careers</li>
                </ul>
            </div>
            <div class="end">
                <h1>Ministries</h1>
                <ul>
                    <li>Children</li>
                    <li>Women</li>
                    <li>Bhagwat</li>
                    <li>Temple</li>
                    <li>Missionaries</li>
                </ul>
            </div>
            <div class="end">
                <h1>Social Icons</h1>
                {/* <i class="fa-brands fa-facebook-f"></i> */}
                <i class="fa fa-facebook-f"></i>
                <i class="fa fa-twitter"></i>
                <a href="https://www.instagram.com/paliwalmessngp?igsh=MWlkY3pkbTVyaTF5Zw=="><i class="fa fa-instagram"></i></a>
                <i class="fa fa-vimeo"></i>
            </div>
            </section>
            <section class="footer2">
                <div>
                <p>Copyright © 2024 All Rights Reserved <i class="fa-solid fa-heart"></i></p>
                </div>
            </section>
        </footer> 
    )
}