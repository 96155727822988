import React from "react";
import { Navbar } from "./Navbar";
import { Offer } from "./Offer";
import { Features } from "./Features";
import { Review } from "./Review";





export const Header = () => {




   



    return (
        <>
        <section className="firstsection">
                <div class="midpart1">
                <h1>Welcome To Paliwal</h1>
                <h2>HOSTEL & MESS</h2>
            </div>
        </section>
        <Features/>
        <Offer/>
        <Review/>

</>
    )
}